
























































































import Vue from "vue";
import Component from "vue-class-component";

import FancyButton from "@/components/FancyButton.vue";

import { Locations, Auth } from '@/store/modules';
import { Location } from '@/store/locations';

@Component({
  components: {
    FancyButton
  },
  name: 'ReturnContainer'
})
export default class ReturnContainer extends Vue {
  isLoading = false;
  loadingError = '';
  isLoadingReturn = false;
  returnOver = false;
  returnError = '';
  location: any = {};
  containers: any = [];
  stopConfettiTimeout: any;
  hiddenReturnTitle = false

  get noContainers() {
    return this.containers.length === 0;
  }

  get returnStation() {
    return this.$route.query.returnStation;
  }

  get noSelected() {
    return this.containers.every((container: any) => !container.selected);
  }

  get containerTypes() {
    return Locations.containerTypes;
  }

  get selectedContainersAmount() {
    return this.containers.reduce((agg: number, cur: any) => {
      return agg + cur.selected;
    }, 0);
  }

  async returnContainer(sub: any) {
    this.isLoadingReturn = true;
    try {
      await Auth.returnContainers({
        locationId: this.returnStation as string,
        containers: this.containers.map((container: any) => ({ id: container.type, amount: container.selected }))
      });
      this.hiddenReturnTitle = true;
      setTimeout(() => {
        (this as any).$confetti.start();
      }, 500);
      this.stopConfettiTimeout = setTimeout(() => {
        (this as any).$confett.stop();
      }, 60000);
    } catch (err) {
      this.returnError = err.message;
    }
    this.isLoadingReturn = false;
    this.returnOver = true;
  }

  beforeDestroy() {
    clearTimeout(this.stopConfettiTimeout);
    (this as any).$confetti.stop();
  }

  async refresh() {
    this.isLoading = true;
    try {
      await Locations.fetchAll();
      const fetchedLocation = await Locations.fetchOne({ slug: this.returnStation as string });
      this.location = Object.assign({}, fetchedLocation);
      const { containers } = await Auth.myContainers();
      this.containers = Object.keys(containers).map(type => ({
        type,
        available: containers[type],
        selected: 0,
      })).filter((container) => container.available > 0);
    } catch (err) {
      this.loadingError = err.message;
    }
    this.isLoading = false;
  }

  getContainerData(id: string) {
    return this.containerTypes.find(containerType => containerType.id === id) || { name: 'Unknown container', size: 'Unknown size' };
  }

  mounted() {
    this.refresh();
  }

  validate(reusable: any) {
    if (reusable.selected > reusable.available) {
      reusable.selected = reusable.available;
    }

    if (reusable.selected < 0) {
      reusable.selected = 0;
    }
  }
}
